import axios from 'axios'
import {
  getByDocumentosPorCategoria,
  getByIsActivoCategoriaDocumentoAdherente,
  postSaveCategoriaDocumentoAdherente,
  putUpdateCategoriaDocumentoAdherente,
} from '@/utils/paths'

const pathGetByDocumentosPorCategoria = getByDocumentosPorCategoria
const pathGetByIsActivoAdhCategoriaDocumentacion = getByIsActivoCategoriaDocumentoAdherente
const pathPostSaveAdhCategoriaDocumentacion = postSaveCategoriaDocumentoAdherente
const pathPutUpdateAdhCategoriaDocumentacion = putUpdateCategoriaDocumentoAdherente

export function afiliadoAdherenteDocumentoCategoriaResource() {
  function findByDocumentosPorCategoria() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByDocumentosPorCategoria)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoAdhCategoriaDocumentacion() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoAdhCategoriaDocumentacion)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveAdhCategoriaDocumentacion(categoriaDocumentacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveAdhCategoriaDocumentacion, categoriaDocumentacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateAdhCategoriaDocumentacion(id, categoriaDocumentacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateAdhCategoriaDocumentacion + id, categoriaDocumentacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findByDocumentosPorCategoria,
    findAllByIsActivoAdhCategoriaDocumentacion,
    saveAdhCategoriaDocumentacion,
    updateAdhCategoriaDocumentacion,
  }
}
