<template>
  <div>
    <validation-observer ref="abmEstadoLaboralForm">
      <b-form>
        <b-overlay
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div class="mt-2">
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
          <b-card>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmEstadoLaboral"
                  label="Nombre"
                >
                  <validation-provider
                    name="nombreEstadoLaboral"
                    rules="required|max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreEstadoLaboral"
                        v-model="itemForm.nombre"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmNombreCustomEstadoLaboral"
                  label="Nombre Particular"
                >
                  <validation-provider
                    name="nombreCustomEstadoLaboral"
                    rules="required|max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreEstadoLaboral"
                        v-model="itemForm.nombreCustom"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre Particular obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmDescripcionEstadoLaboral"
                  label="Descripción"
                >
                  <validation-provider
                    name="descripcionEstadoLaboral"
                    rules="max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtDescripcionEstadoLaboral"
                        v-model="itemForm.descripcion"
                        autocomplete="off"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="frmConvenioTrabajo"
                  label="Convenio de trabajo"
                >
                  <validation-provider
                    name="convenioTrabajo"
                    rules="required"
                  >
                    <div slot-scope="{ errors, failedRules }">

                      <small v-if="isBusyBuscandoConvenioTrabajo">
                        <span
                          v-if="isBusyBuscandoConvenioTrabajo"
                          class="spinner-border spinner-border-sm"
                          aria-hidden="true"
                        /> Buscando convenio de trabajo
                      </small>
                      <b-form-select
                        v-if="!isBusyBuscandoConvenioTrabajo"
                        id="selectConvenioTrabajo"
                        v-model="itemForm.convenioTrabajo.id"
                        v-b-tooltip.hover="'Seleccione el convenio de trabajo'"
                        :state="errors.length > 0 ? false : null"
                        :options="optionsConvenioTrabajo"
                        value-field="id"
                        text-field="nombre"
                        :aria-busy="isBusyBuscandoConvenioTrabajo ? 'true' : 'false'"
                        :disabled="isBusyBuscandoConvenioTrabajo"
                        rounded
                      >
                        <template #first>
                          <b-form-select-option
                            v-if="!isBusyBuscandoConvenioTrabajo"
                            :value="null"
                            disabled
                          >
                            Seleccione
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small
                        v-if="failedRules.required && !isBusyBuscandoConvenioTrabajo"
                        class="text-danger"
                      >
                        Convenio de trabajo obligatorio.
                      </small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="frmChecknEstadoLaboral"
                  label="Estado"
                >
                  <b-form-checkbox
                    v-model="itemForm.isActivo"
                    v-b-tooltip.hover="'Activar / Desactivar'"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <small class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </small>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
        <hr>

        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnGCancelarEstadoLaboral"
                v-b-tooltip.hover
                title="Volver"
                variant="light"
                squared
                :disabled="isWorking"
                @click="() => cancelar()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnGuardarEstadoLaboral"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar"
                :disabled="isWorking"
                type="submit"
                @click.prevent="validationItemsForm"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { estadoLaboralResource } from '@/services/estadoLaboralResource'
import { convenioTrabajoResource } from '@/services/convenioTrabajoResource'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      optionsCategoria: null,
      isBusyBuscandoConvenioTrabajo: false,
      optionsConvenioTrabajo: [],
      nameModulo: 'ABM_PARAMETROS',
      isBussy: false,
      isWorking: false,
      itemForm: {
        nombre: null,
        nombreCustom: null,
        descripcion: null,
        isActivo: null,
        convenioTrabajo: {
          id: null,
          nombre: null,
        },
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  async created() {
    if (this.item === null) {
      this.itemForm.isActivo = true
    } else {
      this.itemForm = { ...this.item }
    }
    await this.getConvenioTrabajoLista()
  },
  methods: {
    cancelar() {
      this.$emit('cancelar')
    },
    validationItemsForm() {
      this.$refs.abmEstadoLaboralForm.validate().then(success => {
        if (success) {
          this.actionItem()
        }
      })
    },
    actionItem() {
      if (this.itemForm.id != null) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      estadoLaboralResource().saveEstadoLaboral(this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    update() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      estadoLaboralResource().updateEstadoLaboral(this.itemForm.id, this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    getConvenioTrabajoLista() {
      this.isBusyBuscandoConvenioLaboral = true
      convenioTrabajoResource().findAllByIsActivoConvenioLaboral().then(result => {
        this.optionsConvenioTrabajo = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoConvenioLaboral = false
        })
    },
  },
}
</script>
