<template>
  <div>
    <validation-observer ref="abmEstadoAfiliacionForm">
      <b-form>
        <b-overlay
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div class="mt-2">
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
          <b-card>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmEstadoAfiliacion"
                  label="Nombre"
                >
                  <validation-provider
                    name="nombreEstadoAfiliacion"
                    rules="required|max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreEstadoAfiliacion"
                        v-model="itemForm.nombre"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmNombreCustomEstadoAfiliacion"
                  label="Nombre Particular"
                >
                  <validation-provider
                    name="nombreCustomEstadoAfiliacion"
                    rules="required|max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreEstadoAfiliacion"
                        v-model="itemForm.nombreCustom"
                        :state="errors.length > 0 ? false:null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre Particular obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmDescripcionEstadoAfiliacion"
                  label="Descripción"
                >
                  <validation-provider
                    name="descripcionEstadoAfiliacion"
                    rules="max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtDescripcionEstadoAfiliacion"
                        v-model="itemForm.descripcion"
                        autocomplete="off"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmChecknEstadoAfiliacion"
                  label="Estado"
                >
                  <b-form-checkbox
                    v-model="itemForm.isActivo"
                    v-b-tooltip.hover="'Activar / Desactivar'"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <small class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </small>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
        <hr>

        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnGCancelarEstadoAfiliacion"
                v-b-tooltip.hover
                title="Volver"
                variant="light"
                squared
                :disabled="isWorking"
                @click="()=>cancelar()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnGuardarEstadoAfiliacion"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar"
                :disabled="isWorking"
                type="submit"
                @click.prevent="validationItemsForm"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { afiliacionEstadoResource } from '@/services/afiliacionEstadoResource'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      optionsCategoria: null,
      nameModulo: 'ABM_PARAMETROS',
      isBussy: false,
      isWorking: false,
      itemForm: { ...this.item },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    if (this.item === null) {
      this.itemForm.isActivo = true
    }
  },
  methods: {
    cancelar() {
      this.$emit('cancelar')
    },
    validationItemsForm() {
      this.$refs.abmEstadoAfiliacionForm.validate().then(success => {
        if (success) {
          this.actionItem()
        }
      })
    },
    actionItem() {
      if (this.itemForm.id != null) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      afiliacionEstadoResource().saveAfiliacionEstado(this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    update() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      afiliacionEstadoResource().updateAfiliacionEstado(this.itemForm.id, this.itemForm).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
  },
}
</script>
