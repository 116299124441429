<template>
  <div>
    <b-modal
      v-model="showReglaAdherenteModal"
      :title="title"
      no-close-on-backdrop
      size="lg"
      hide-header-close
      @close="closeModal"
    >
      <validation-observer ref="refReglaAdherente">
        <b-row>
          <b-col md="6">
            <b-form-group
              id="frmRelacionFamiliar"
              label="Parentesco"
            >
              <validation-provider
                name="parentesco"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">

                  <small v-if="isBusyBuscandoRelacionFamiliar">
                    <span
                      v-if="isBusyBuscandoRelacionFamiliar"
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    /> Buscando parentesco
                  </small>
                  <b-form-select
                    v-if="!isBusyBuscandoRelacionFamiliar"
                    id="selectRelacionFamiliar"
                    v-model="reglaAdherenteDto.relacionFamiliar.id"
                    v-b-tooltip.hover="'Seleccione el parentesco'"
                    :state="errors.length > 0 ? false : null"
                    :options="optionsRelacionFamiliar"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoRelacionFamiliar ? 'true' : 'false'"
                    :disabled="isBusyBuscandoRelacionFamiliar || !isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        v-if="!isBusyBuscandoRelacionFamiliar"
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required && !isBusyBuscandoRelacionFamiliar"
                    class="text-danger"
                  >
                    Parentesco obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="frmTipoDocumento"
              label="Tipo documento"
            >
              <validation-provider
                name="tipoDocumento"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-select
                    id="selectTipoDocumento"
                    v-model="reglaAdherenteDto.documentoTipo.id"
                    v-b-tooltip.hover="'Seleccione el tipo de documento'"
                    :state="errors.length > 0 ? false : null"
                    :options="optionsTipoDocumento"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoTipoDocumento ? 'true' : 'false'"
                    :disabled="!isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >
                    Tipo de documento obligatorio.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              id="frmEdadDesde"
              label="Edad Desde"
            >
              <validation-provider
                name="nombre"
                rules="required|min:0"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtEdadDesde"
                    v-model="reglaAdherenteDto.edadDesde"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                    type="number"
                    maxlength="2"
                    min="0"
                    oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Edad desde obligatorio.</small>
                  <small
                    v-if="failedRules.min"
                    class="text-danger"
                  >Edad desde debe ser mayor a 0.</small>
                  <small
                    v-if="!validateEdadDesde()"
                    class="text-danger"
                  >Edad desde debe ser menor que Edad hasta.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="frmEdadHasta"
              label="Edad Hasta"
            >
              <validation-provider
                name="nombre"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-input
                    id="txtEdadHasta"
                    v-model="reglaAdherenteDto.edadHasta"
                    :state="errors.length > 0 ? false : null"
                    autocomplete="off"
                    type="number"
                    maxlength="2"
                    oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  />
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >Edad hasta obligatorio.</small>
                  <small
                    v-if="!validateEdadHasta()"
                    class="text-danger"
                  >Edad hasta debe ser mayor que Edad desde.</small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div
          v-if="openModeModal != 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="danger"
            @click="closeModal"
          >
            Cancelar
          </b-button>
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="agregarNuevaReglaAdherente"
          >{{ tituloBotonAgregar }}
          </b-button>
        </div>
        <div
          v-if="openModeModal == 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="closeModal"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { documentoTipoResource } from '@/services/documentoTipoResource'
import { relacionFamiliarResource } from '@/services/relacionFamiliarResource'

export default {
  components: {
  },
  props: {
    modalDataReglaAdherente: {
      type: Object,
      default: null,
    },
    showReglaAdherenteModal: {
      type: Boolean,
      required: true,
    },
    openModeModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameModulo: 'ABM_AFILIADOS',
      reglaAdherenteDto: {
        id: null,
        afiliadoAdherenteDocumentoCategoria: {
          id: null,
          nombre: null,
        },
        edadDesde: null,
        edadHasta: null,
        relacionFamiliar: {
          id: null,
          nombre: null,
        },
        documentoTipo: {
          id: null,
          nombre: null,
        },
      },
      isBusyBuscandoRelacionFamiliar: false,
      isBusyBuscandoTipoDocumento: false,
      optionsRelacionFamiliar: [],
      optionsTipoDocumento: [],
    }
  },
  computed: {
    isEditable() {
      return (this.openModeModal === 'new' || this.openModeModal === 'edit' || this.openModeModal === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
    tituloBotonAgregar() {
      return this.openModeModal === 'new' ? 'Agregar' : 'Modificar'
    },
    title() {
      return this.openModeModal === 'new' ? 'Nueva regla Adherente' : 'Edición regla Adherente'
    },
  },
  created() {
    this.iniciar()
  },
  methods: {
    async iniciar() {
      await this.getRelacionFamiliarLista()
      await this.getTipoDocumentoLista()
      if (this.modalDataReglaAdherente && this.modalDataReglaAdherente.id) {
        this.reglaAdherenteDto = { ...this.modalDataReglaAdherente }
      }
    },
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    async agregarNuevaReglaAdherente() {
      return this.$refs.refReglaAdherente.validate().then(async success => {
        if (success) {
          this.findByNombres()
          if (this.reglaAdherenteDto.id === null) {
            this.reglaAdherenteDto.id = this.generarRandomId()
            this.$emit('agregarReglaAdherente', this.reglaAdherenteDto)
          } else {
            this.$emit('setReglaAdherente', this.reglaAdherenteDto)
          }
        }
      })
    },
    closeModal() {
      this.$emit('closeModalReglaAdherente')
    },
    findByNombres() {
      this.optionsRelacionFamiliar.forEach(element => {
        if (element.id === this.reglaAdherenteDto.relacionFamiliar.id) {
          this.reglaAdherenteDto.relacionFamiliar.nombre = element.nombre
        }
      })
      this.optionsTipoDocumento.forEach(element => {
        if (element.id === this.reglaAdherenteDto.documentoTipo.id) {
          this.reglaAdherenteDto.documentoTipo.nombre = element.nombre
        }
      })
    },
    validateEdadDesde() {
      const { edadDesde, edadHasta } = this.reglaAdherenteDto
      return !edadDesde || !edadHasta || parseInt(edadDesde, 10) < parseInt(edadHasta, 10)
    },
    validateEdadHasta() {
      const { edadDesde, edadHasta } = this.reglaAdherenteDto
      return !edadDesde || !edadHasta || parseInt(edadDesde, 10) < parseInt(edadHasta, 10)
    },
    getRelacionFamiliarLista() {
      this.isBusyBuscandoRelacionFamiliar = true
      return relacionFamiliarResource().findAllByIsActivoRelacionFamiliar().then(result => {
        this.optionsRelacionFamiliar = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoRelacionFamiliar = false
        })
    },
    getTipoDocumentoLista() {
      this.isBusyBuscandoTipoDocumento = true
      return documentoTipoResource().findAllDocumentoTipo().then(result => {
        this.optionsTipoDocumento = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoTipoDocumento = false
        })
    },
  },
}
</script>
