<template>
  <div>
    <b-card>
      <h4>{{ titulo }}</h4>
      <estadoLaboral
        v-if="editando"
        :item="selectedItem"
        @cancelar="cancelar"
        @guardar="guardar"
      />
      <div v-if="!editando">
        <b-row>
          <b-col
            md="12"
            class="text-right"
          >
            <b-button
              v-if="permisosIns.includes(nameModulo)"
              id="btnAddAfiliacionEstado"
              v-b-tooltip.hover
              squared
              style="float: right;"
              size="sm"
              title="Nuevo"
              variant="success"
              @click="() => addItem()"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-table
          id="tblEmpCate"
          :busy="isBussy"
          :items="options"
          :fields="fieldsOptions"
          outlined
          show-empty
          small
          hover
          sticky-header="70vh"
          :no-border-collapse="true"
          empty-text="No se encontraron resultados para esta búsqueda"
          @row-dblclicked="editarItem"
        >
          <template v-slot:table-busy>
            <div class="text-center">
              <b-spinner />
            </div>
          </template>
          <template #cell(isActivo)="row">
            <small>
              {{ row.item.isActivo === true ? 'Activo' : 'Inactivo' }}
            </small>

          </template>
          <template #cell(actions)="row">
            <div
              class="btn-group"
              role="group"
              aria-label="Basic example"
            >
              <b-button
                v-if="permisosUpd.includes(nameModulo)"
                v-b-tooltip.hover.righttop="'Editar item'"
                size="sm"
                @click="editarItem(row.item, row.index, $event.target)"
              >
                <font-awesome-icon icon="fa-regular fa-pen-to-square" />
              </b-button>
            </div>
          </template>
        </b-table>
      </div>

    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { estadoLaboralResource } from '@/services/estadoLaboralResource'
import EstadoLaboral from '@/components/Ambs/EstadoLaboral/EstadoLaboral.vue'

export default {
  components: {
    EstadoLaboral,
  },
  data() {
    return {
      editando: false,
      options: null,
      nameModulo: 'ABM_PARAMETROS',
      fieldsOptions: [
        {
          key: 'nombre', label: 'Género',
        },
        {
          key: 'nombreCustom', label: 'Nombre Particular',
        },
        {
          key: 'descripcion', label: 'Descripción', thStyle: { width: '25%' },
        },
        {
          key: 'convenioTrabajo.nombre', label: 'Convenio Trabajo',
        },
        {
          key: 'isActivo', label: 'Estado', thStyle: { width: '5%' },
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
      isBussy: false,
    }
  },
  computed: {
    titulo() {
      let titulo = 'Gestión estado laboral'
      if (this.editando) {
        if (this.selectedItem !== null) {
          titulo = 'Edición estado laboral'
        } else {
          titulo = 'Nuevo estado laboral'
        }
      }
      return titulo
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.getLista()
  },
  methods: {
    addItem() {
      this.selectedItem = null
      this.editando = true
    },
    editarItem(item) {
      this.selectedItem = item
      this.editando = true
    },
    getLista() {
      this.isBussy = true

      estadoLaboralResource().findAllEstadoLaboral().then(result => {
        this.options = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBussy = false
        })
    },
    cancelar() {
      this.editando = false
      this.getLista()
    },
    guardar() {
      this.getLista()
      this.editando = false
    },
  },
}
</script>
