var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"title":_vm.title,"no-close-on-backdrop":"","size":"lg","hide-header-close":""},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(_vm.openModeModal != 'view')?_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"size":"sm","squared":"","variant":"danger"},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('b-button',{attrs:{"size":"sm","squared":"","variant":"primary"},on:{"click":_vm.agregarNuevaReglaAdherente}},[_vm._v(_vm._s(_vm.tituloBotonAgregar)+" ")])],1):_vm._e(),(_vm.openModeModal == 'view')?_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"size":"sm","squared":"","variant":"primary"},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1):_vm._e()]},proxy:true}]),model:{value:(_vm.showReglaAdherenteModal),callback:function ($$v) {_vm.showReglaAdherenteModal=$$v},expression:"showReglaAdherenteModal"}},[_c('validation-observer',{ref:"refReglaAdherente"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmRelacionFamiliar","label":"Parentesco"}},[_c('validation-provider',{attrs:{"name":"parentesco","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[(_vm.isBusyBuscandoRelacionFamiliar)?_c('small',[(_vm.isBusyBuscandoRelacionFamiliar)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v(" Buscando parentesco ")]):_vm._e(),(!_vm.isBusyBuscandoRelacionFamiliar)?_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Seleccione el parentesco'),expression:"'Seleccione el parentesco'",modifiers:{"hover":true}}],attrs:{"id":"selectRelacionFamiliar","state":errors.length > 0 ? false : null,"options":_vm.optionsRelacionFamiliar,"value-field":"id","text-field":"nombre","aria-busy":_vm.isBusyBuscandoRelacionFamiliar ? 'true' : 'false',"disabled":_vm.isBusyBuscandoRelacionFamiliar || !_vm.isEditable,"rounded":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [(!_vm.isBusyBuscandoRelacionFamiliar)?_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" Seleccione ")]):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.reglaAdherenteDto.relacionFamiliar.id),callback:function ($$v) {_vm.$set(_vm.reglaAdherenteDto.relacionFamiliar, "id", $$v)},expression:"reglaAdherenteDto.relacionFamiliar.id"}}):_vm._e(),(failedRules.required && !_vm.isBusyBuscandoRelacionFamiliar)?_c('small',{staticClass:"text-danger"},[_vm._v(" Parentesco obligatorio. ")]):_vm._e()],1)}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmTipoDocumento","label":"Tipo documento"}},[_c('validation-provider',{attrs:{"name":"tipoDocumento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Seleccione el tipo de documento'),expression:"'Seleccione el tipo de documento'",modifiers:{"hover":true}}],attrs:{"id":"selectTipoDocumento","state":errors.length > 0 ? false : null,"options":_vm.optionsTipoDocumento,"value-field":"id","text-field":"nombre","aria-busy":_vm.isBusyBuscandoTipoDocumento ? 'true' : 'false',"disabled":!_vm.isEditable,"rounded":""},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v(" Seleccione ")])]},proxy:true}],null,true),model:{value:(_vm.reglaAdherenteDto.documentoTipo.id),callback:function ($$v) {_vm.$set(_vm.reglaAdherenteDto.documentoTipo, "id", $$v)},expression:"reglaAdherenteDto.documentoTipo.id"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v(" Tipo de documento obligatorio. ")]):_vm._e()],1)}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmEdadDesde","label":"Edad Desde"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required|min:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"txtEdadDesde","state":errors.length > 0 ? false : null,"autocomplete":"off","type":"number","maxlength":"2","min":"0","oninput":"if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"},model:{value:(_vm.reglaAdherenteDto.edadDesde),callback:function ($$v) {_vm.$set(_vm.reglaAdherenteDto, "edadDesde", $$v)},expression:"reglaAdherenteDto.edadDesde"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Edad desde obligatorio.")]):_vm._e(),(failedRules.min)?_c('small',{staticClass:"text-danger"},[_vm._v("Edad desde debe ser mayor a 0.")]):_vm._e(),(!_vm.validateEdadDesde())?_c('small',{staticClass:"text-danger"},[_vm._v("Edad desde debe ser menor que Edad hasta.")]):_vm._e()],1)}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"frmEdadHasta","label":"Edad Hasta"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"txtEdadHasta","state":errors.length > 0 ? false : null,"autocomplete":"off","type":"number","maxlength":"2","oninput":"if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"},model:{value:(_vm.reglaAdherenteDto.edadHasta),callback:function ($$v) {_vm.$set(_vm.reglaAdherenteDto, "edadHasta", $$v)},expression:"reglaAdherenteDto.edadHasta"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Edad hasta obligatorio.")]):_vm._e(),(!_vm.validateEdadHasta())?_c('small',{staticClass:"text-danger"},[_vm._v("Edad hasta debe ser mayor que Edad desde.")]):_vm._e()],1)}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }