<template>
  <div>
    <validation-observer ref="abmCategoriaDocumentoAdherenteForm">
      <b-form>
        <b-overlay
          :show="isWorking"
          :rounded="overlayConfig.rounded"
          :variant="overlayConfig.variant"
          :opacity="overlayConfig.opacity"
          :blur="overlayConfig.blur"
        >
          <template #overlay>
            <div class="mt-2">
              <div style="text-align: center">
                <span class="spinner-border " />
              </div>
              <div style="text-align: center">
                <small>{{ overlayConfig.message }}</small>
              </div>
            </div>
          </template>
          <b-card>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="frmNombreCategoría"
                  label="Nombre"
                >
                  <validation-provider
                    name="nombreCategoria"
                    rules="required|max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtNombreCategoria"
                        v-model="itemForm.nombre"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.required"
                        class="text-danger"
                      >Nombre obligatorio.</small>
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="frmDescripcionCategoría"
                  label="Descripción"
                >
                  <validation-provider
                    name="descripcionCategoria"
                    rules="max:255"
                  >
                    <div slot-scope="{ errors, failedRules }">
                      <b-form-input
                        id="txtDescripcionCategoria"
                        v-model="itemForm.descripcion"
                        :state="errors.length > 0 ? false : null"
                        autocomplete="off"
                      />
                      <small
                        v-if="failedRules.max"
                        class="text-danger"
                      >Máximo 255 caracteres.</small>
                    </div>
                  </validation-provider></b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group
                  id="frmChecknCategoría"
                  label="Estado"
                >
                  <b-form-checkbox
                    v-model="itemForm.habilitado"
                    v-b-tooltip.hover="'Activar / Desactivar'"
                    class="custom-control-success"
                    name="check-button"
                    switch
                  >
                    <small class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </small>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                <h5>
                  <b>Reglas</b>
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-table
                  id="tblReglasAdherente"
                  ref="refTblReglaAdherente"
                  :busy="isWorking"
                  :fields="fieldsReglasAdherente"
                  :items="tableDataReglaAdherente"
                  outlined
                  show-empty
                  small
                  hover
                  sticky-header="70vh"
                  :no-border-collapse="true"
                  empty-text="No existe reglas cargadas para esta categoría"
                  @row-dblclicked="editDetalleReglaAdherente"
                >
                  <template v-slot:table-busy>
                    <div class="text-center">
                      <b-spinner />
                    </div>
                  </template>
                  <template #cell(actions)="row">
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <b-button
                        v-if="permisosUpd.includes(nameModulo)"
                        v-b-tooltip.hover.righttop="'Editar regla'"
                        size="sm"
                        @click="editDetalleReglaAdherente(row.item, row.index, $event.target)"
                      >
                        <font-awesome-icon icon="fa-regular fa-pen-to-square" />
                      </b-button>
                      <b-button
                        v-if="permisosDel.includes(nameModulo)"
                        v-b-tooltip.hover.righttop="'Eliminar regla'"
                        size="sm"
                        @click="quitarReglaSindical(row.item, row.index, $event.target)"
                      >
                        <font-awesome-icon icon="fa-solid fa-trash" />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="permisosIns.includes(nameModulo)"
                md="12"
                class="text-right"
              >
                <b-button
                  variant="primary"
                  @click="openModalAddReglaAdherente"
                >
                  Agregar regla
                </b-button>
              </b-col>
            </b-row>
            <br>
          </b-card>
        </b-overlay>
        <hr>
        <b-row>
          <div>
            <b-col md="12">
              <b-button
                id="btnGCancelarCat"
                v-b-tooltip.hover
                title="Volver"
                variant="light"
                squared
                @click="() => cancelar()"
              >
                <feather-icon icon="ArrowLeftIcon" />
              </b-button>
            </b-col>
          </div>
          <div class="align-self-end ml-auto">
            <b-col md="12">
              <b-button
                v-if="permisosIns.includes(nameModulo)"
                id="btnGuardarCat"
                v-b-tooltip.hover
                variant="primary"
                squared
                title="Guardar"
                type="submit"
                @click.prevent="validationCategoriaDocumentoAdherenteForm"
              >
                <font-awesome-icon
                  v-if="!isWorking"
                  icon="fa-solid fa-floppy-disk"
                />
                <span
                  v-if="isWorking"
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                />
              </b-button>
            </b-col>
          </div>
        </b-row>
      </b-form>
    </validation-observer>
    <div v-if="showReglaAdherenteModal">
      <reglas-documentacion-adherente-modal
        :show-regla-adherente-modal="showReglaAdherenteModal"
        :open-mode-modal="openModeModal"
        :modal-data-regla-adherente="modalDataReglaAdherente"
        @agregarReglaAdherente="agregarReglaAdherente"
        @editarReglaAdherente="editarReglaAdherente"
        @setReglaAdherente="setReglaAdherente"
        @closeModalReglaAdherente="closeModalReglaAdherente"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { afiliadoAdherenteDocumentoCategoriaResource } from '@/services/afiliadoAdherenteDocumentoCategoriaResource'
import { afiliadoAdherenteDocumentoReglasResource } from '@/services/afiliadoAdherenteDocumentoReglasResource'
import ReglasDocumentacionAdherenteModal from './ReglasDocumentacionAdherenteModal.vue'

export default {
  components: {
    ReglasDocumentacionAdherenteModal,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      optionsCategoria: null,
      nameModulo: 'ABM_PARAMETROS',
      isBussy: false,
      isWorking: false,
      showReglaAdherenteModal: false,
      itemForm: {
        id: null,
        codigo: null,
        nombre: null,
        descripcion: null,
        habilitado: null,
      },
      overlayConfig: {
        rounded: 'sm',
        variant: 'transparent',
        opacity: '0.8',
        blur: '5px',
        message: '',
      },
      reglasToBeRemoved: [],
      tableDataReglaAdherenteTemporal: [],
      tableDataReglaAdherente: [],
      fieldsReglasAdherente: [
        {
          key: 'documentoTipo.nombre', label: 'Tipo documento',
        },
        {
          key: 'edadDesde', label: 'Edad Desde',
        },
        {
          key: 'edadHasta', label: 'Edad Hasta',
        },
        {
          key: 'relacionFamiliar.nombre', label: 'Parentesco',
        },
        { key: 'actions', label: 'Acciones', thStyle: { width: '10%' } },
      ],
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    if (this.item === null) {
      this.itemForm.habilitado = true
    } else {
      this.itemForm = { ...this.item }
      this.findByIdCategoria(this.itemForm.id)
    }
  },
  methods: {
    toast(type, title, html) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">${html}</p>`
      this.$swal({
        icon: type,
        title,
        html: htmlText,
        background: this.swalConfig.background,
        confirmButtonText: 'Aceptar',
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
      })
    },
    cancelar() {
      this.$emit('cancelar')
    },
    validationCategoriaDocumentoAdherenteForm() {
      this.$refs.abmCategoriaDocumentoAdherenteForm.validate().then(success => {
        if (success) {
          this.actionCategoriaDocumentoAdherente()
        }
      })
    },
    async actionCategoriaDocumentoAdherente() {
      let categoriaId = null
      if (this.tableDataReglaAdherente.length > 0) {
        if (this.reglasToBeRemoved.length > 0) {
          this.reglasToBeRemoved.forEach(async item => {
            await this.deleteReglaAdherente(item)
          })
          this.reglasToBeRemoved = []
        }
        if (this.itemForm.id != null) {
          categoriaId = await this.update()
        } else {
          categoriaId = await this.save()
        }
        this.tableDataReglaAdherenteTemporal.map(async element => {
        // eslint-disable-next-line no-param-reassign
          element.afiliadoAdherenteDocumentoCategoria.id = categoriaId.id
          if (element.id.toString().includes('generated')) {
          // eslint-disable-next-line no-param-reassign
            element.id = null
          }
          if (element.id === null) {
            this.saveReglaAdherente(element)
          } else {
            this.updateReglaAdherente(element)
          }
        })
        this.tableDataReglaAdherenteTemporal = []
      } else {
        this.toast('warning', '', 'Debe agregar como mínimo una regla antes de guardar.')
      }
    },
    save() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true

      return afiliadoAdherenteDocumentoCategoriaResource()
        .saveAdhCategoriaDocumentacion(this.itemForm)
        .then(res => {
          this.$emit('guardar', res)
          return res
        })
        .catch(error => {
          console.error(error)
          throw error
        })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    update() {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true

      return afiliadoAdherenteDocumentoCategoriaResource().updateAdhCategoriaDocumentacion(this.itemForm.id, this.itemForm).then(res => {
        this.$emit('guardar', res)
        return res
      }).catch(error => {
        console.error(error)
        throw error
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    saveReglaAdherente(reglaAdherente) {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      afiliadoAdherenteDocumentoReglasResource().saveReglaAdherente(reglaAdherente).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    updateReglaAdherente(reglaAdherente) {
      this.overlayConfig.message = 'Guardando ...'
      this.isWorking = true
      afiliadoAdherenteDocumentoReglasResource().updateReglaAdherente(reglaAdherente.id, reglaAdherente).then(res => {
        this.$emit('guardar', res)
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
          this.overlayConfig.message = ''
        })
    },
    async findByIdCategoria(categoriaId) {
      this.isWorking = true
      afiliadoAdherenteDocumentoReglasResource().findByReglasPorCategoria(categoriaId)
        .then(resultListReglas => {
          this.tableDataReglaAdherente = resultListReglas
        }).catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isWorking = false
        })
    },
    agregarReglaAdherente(ReglaAdherente) {
      this.showReglaAdherenteModal = false
      this.tableDataReglaAdherente.push(ReglaAdherente)
      this.tableDataReglaAdherenteTemporal.push(ReglaAdherente)
    },
    editarReglaAdherente(ReglaAdherente) {
      this.showReglaAdherenteModal = false
      this.tableDataReglaAdherenteTemporal.push(ReglaAdherente)
    },
    closeModalReglaAdherente() {
      this.showReglaAdherenteModal = false
    },
    openModalAddReglaAdherente() {
      this.openModeModal = 'new'
      this.showReglaAdherenteModal = true
      this.modalDataReglaAdherente = null
    },
    editDetalleReglaAdherente(data) {
      this.openModeModal = 'edit'
      this.showReglaAdherenteModal = true
      this.modalDataReglaAdherente = data
    },
    setReglaAdherente(data) {
      this.showReglaAdherenteModal = false
      const pos = this.tableDataReglaAdherente.findIndex(afiAdh => afiAdh.id === data.id)
      this.tableDataReglaAdherente[pos] = { ...data }
      if (this.tableDataReglaAdherenteTemporal.find(adherenteTemp => adherenteTemp.id === data.id)) {
        const posTemp = this.tableDataReglaAdherenteTemporal.findIndex(afiAdh => afiAdh.id === data.id)
        this.tableDataReglaAdherenteTemporal[posTemp] = { ...data }
      } else {
        this.tableDataReglaAdherenteTemporal.push(data)
      }
      this.$refs.refTblReglaAdherente.refresh()
    },
    async deleteReglaAdherente(reglaToRemoved) {
      this.isWorking = true
      afiliadoAdherenteDocumentoReglasResource().deleteReglaAdherenteCategoria(reglaToRemoved.id).then(() => {
        this.isWorking = false
      }).catch(error => {
        console.error(error)
      })
        .finally(() => {
          this.isWorking = false
        })
    },
    quitarReglaSindical(item, index) {
      this.customizeSweetAlert2()
      const htmlText = `<p style="color:${this.swalConfig.textColor}">¿Desea quitar esta regla adherente? </p>`
      this.$swal({
        title: 'Quitar regla adherente',
        html: htmlText,
        icon: 'warning',
        background: this.swalConfig.background,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary square',
          cancelButton: 'btn btn-danger square',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          if (!item.id.toString().includes('generated')) {
            this.reglasToBeRemoved.push(item)
            const reglaIndex = this.tableDataReglaAdherente.findIndex(regla => regla.id === item.id)
            if (reglaIndex !== -1) {
              this.tableDataReglaAdherente.splice(reglaIndex, 1)
            }
          } else {
            this.tableDataReglaAdherente.splice(index, 1)
          }
        }
      })
    },
  },
}
</script>
