<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="3">
          <h4>{{ titulo }}</h4>
        </b-col>
        <b-col md="9">
          <b-form-select
            id="selectTipoAbm"
            v-model="parametroAbmSeleccionado"
            v-b-tooltip.hover="'Seleccione el parametro'"
            rounded
          >
            <option
              v-for="obj in optionComponentesAbmParametros"
              :key="obj.id"
              :value="obj"
            >
              {{ obj.nombre }}
            </option>
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Seleccione
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="parametroAbmSeleccionado != null">
      <component :is="parametroAbmSeleccionado.nombreComponente" />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CategoriaEmpleadorLista from '@/components/Ambs/CategoriaEmpleador/CategoriaEmpleadorLista.vue'
import CategoriaSindicalLista from '@/components/Ambs/CategoriaSindical/CategoriaSindicalLista.vue'
import ConvenioTrabajoLista from '@/components/Ambs/ConvenioTrabajo/ConvenioTrabajoLista.vue'
import EstadoAfiliacionLista from '@/components/Ambs/EstadoAfiliacion/EstadoAfiliacionLista.vue'
import EstadoCivilLista from '@/components/Ambs/EstadoCivil/EstadoCivilLista.vue'
import GeneroLista from '@/components/Ambs/Genero/GeneroLista.vue'
import MotivoBajaAfiliadoLista from '@/components/Ambs/MotivoBajaAfiliado/MotivoBajaAfiliadoLista.vue'
import RelacionFamiliarLista from '@/components/Ambs/RelacionFamiliar/RelacionFamiliarLista.vue'

import CategoriaLaboralLista from '@/components/Ambs/CategoriaLaboral/CategoriaLaboralLista.vue'
import EstadoLaboralLista from '@/components/Ambs/EstadoLaboral/EstadoLaboralLista.vue'
import DelegacionLista from '@/components/Ambs/Delegacion/DelegacionLista.vue'
import CategoriaDocumentacionEmpleadorLista from '@/components/Ambs/CategoriaDocumentacionEmpleador/CategoriaDocumentacionEmpleadorLista.vue'
import CategoriaDocumentacionAfiliadoLista from '@/components/Ambs/CategoriaDocumentacionAfiliado/CategoriaDocumentacionAfiliadoLista.vue'
import CategoriaDocumentacionAdherenteLista from '@/components/Ambs/CategoriaDocumentacionAdherente/CategoriaDocumentacionAdherenteLista.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CategoriaEmpleadorLista,
    CategoriaSindicalLista,
    ConvenioTrabajoLista,
    EstadoAfiliacionLista,
    EstadoCivilLista,
    GeneroLista,
    MotivoBajaAfiliadoLista,
    RelacionFamiliarLista,
    CategoriaLaboralLista,
    EstadoLaboralLista,
    DelegacionLista,
    CategoriaDocumentacionEmpleadorLista,
    CategoriaDocumentacionAfiliadoLista,
    CategoriaDocumentacionAdherenteLista,
  },
  data() {
    return {
      titulo: 'Parámetros',
      parametroAbmSeleccionado: null,
      optionComponentesAbmParametros: [{
        id: 1,
        nombre: 'Categoría empleador',
        nombreComponente: 'CategoriaEmpleadorLista',
      },
      {
        id: 2,
        nombre: 'Categoría sindical',
        nombreComponente: 'CategoriaSindicalLista',
      },
      {
        id: 3,
        nombre: 'Convenio trabajo',
        nombreComponente: 'ConvenioTrabajoLista',
      },
      {
        id: 4,
        nombre: 'Estado afiliación',
        nombreComponente: 'EstadoAfiliacionLista',
      },
      {
        id: 5,
        nombre: 'Estado civil',
        nombreComponente: 'EstadoCivilLista',
      },
      {
        id: 6,
        nombre: 'Género',
        nombreComponente: 'GeneroLista',
      },
      {
        id: 7,
        nombre: 'Motivo baja afiliado',
        nombreComponente: 'MotivoBajaAfiliadoLista',
      },
      {
        id: 8,
        nombre: 'Relación familiar',
        nombreComponente: 'RelacionFamiliarLista',
      },
      {
        id: 9,
        nombre: 'Categoría laboral',
        nombreComponente: 'CategoriaLaboralLista',
      },
      {
        id: 10,
        nombre: 'Estado laboral',
        nombreComponente: 'EstadoLaboralLista',
      },
      {
        id: 11,
        nombre: 'Delegación',
        nombreComponente: 'DelegacionLista',
      },
      {
        id: 12,
        nombre: 'Categoría documentación empleador',
        nombreComponente: 'CategoriaDocumentacionEmpleadorLista',
      },
      {
        id: 13,
        nombre: 'Categoría documentación afiliado',
        nombreComponente: 'CategoriaDocumentacionAfiliadoLista',
      },
      {
        id: 14,
        nombre: 'Categoría documentación adherente',
        nombreComponente: 'CategoriaDocumentacionAdherenteLista',
      }],
    }
  },
  computed: {
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
  },
  created() {
    this.optionComponentesAbmParametros.sort((a, b) => a.nombre.localeCompare(b.nombre))
    //  this.parametroAbmSeleccionado = this.optionComponentesAbmParametros[0].nombre
  },
}
</script>
