<template>
  <div>
    <b-modal
      v-model="showReglaSindicalModal"
      :title="title"
      no-close-on-backdrop
      size="lg"
      hide-header-close
      @close="closeModal"
    >
      <validation-observer ref="refReglaSindical">
        <b-row>
          <b-col md="6">
            <b-form-group
              id="frmCategoriaSindical"
              label="Categoría sindical"
            >
              <validation-provider
                name="categoriaSindical"
                rules="required"
              >
                <div slot-scope="{ errors, failedRules }">
                  <b-form-select
                    id="selectCategoriaSindical"
                    v-model="reglaSindicalDto.categoriaSindical.id"
                    v-b-tooltip.hover="'Seleccione el tipo de categoría sindical'"
                    :state="errors.length > 0 ? false : null"
                    :options="optionsCategoriaSindical"
                    value-field="id"
                    text-field="nombre"
                    :aria-busy="isBusyBuscandoCategoriaSindical ? 'true' : 'false'"
                    :disabled="!isEditable"
                    rounded
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Seleccione
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="failedRules.required"
                    class="text-danger"
                  >
                    Categoría sindical obligatoria.
                  </small>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              id="frmChecknCategoríaObligatorio"
              label="Obligatorio"
            >
              <b-form-checkbox
                v-model="reglaSindicalDto.obligatorio"
                v-b-tooltip.hover="'Activar / Desactivar'"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <small class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </small>
              </b-form-checkbox>

            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div
          v-if="openModeModal != 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="danger"
            @click="closeModal"
          >
            Cancelar
          </b-button>
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="agregarNuevaReglaSindical"
          >{{ tituloBotonAgregar }}
          </b-button>
        </div>
        <div
          v-if="openModeModal == 'view'"
          class="d-flex justify-content-between"
        >
          <b-button
            size="sm"
            squared
            variant="primary"
            @click="closeModal"
          >
            Cerrar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { categoriaSindicalResource } from '@/services/categoriaSindicalResource'
import { afiliadoDocumentoCategoriaResource } from '@/services/afiliadoDocumentoCategoriaResource'

export default {
  components: {
  },
  props: {
    modalDataReglaSindical: {
      type: Object,
      default: null,
    },
    idDocumentoSindical: {
      type: Number,
      default: null,
    },
    showReglaSindicalModal: {
      type: Boolean,
      required: true,
    },
    openModeModal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nameModulo: 'ABM_AFILIADOS',
      reglaSindicalDto: {
        id: null,
        afiliadoDocumentoCategoria: {
          id: null,
          nombre: null,
        },
        categoriaSindical: {
          id: null,
          nombre: null,
        },
        obligatorio: null,
      },
      isBusyBuscandoAfiliadoDocumentoCategoria: false,
      isBusyBuscandoCategoriaSindical: false,
      optionsAfiliadoDocumentoCategoria: [],
      optionsCategoriaSindical: [],
    }
  },
  computed: {
    isEditable() {
      return (this.openModeModal === 'new' || this.openModeModal === 'edit' || this.openModeModal === 'incompleteLoad' || this.editarEnableDisable)
    },
    ...mapGetters(
      {
        permisosVer: 'getPermisosVer',
        permisosDel: 'getPermisosDel',
        permisosIns: 'getPermisosIns',
        permisosUpd: 'getPermisosUpd',
      },
    ),
    tituloBotonAgregar() {
      return this.openModeModal === 'new' ? 'Agregar' : 'Modificar'
    },
    title() {
      return this.openModeModal === 'new' ? 'Nueva regla Sindical' : 'Edición regla Sindical'
    },
  },
  created() {
    this.iniciar()
  },
  methods: {
    async iniciar() {
      await this.getAfiliadoDocumentoCategoriaLista()
      await this.getCategoriaSindicalLista()
      if (this.modalDataReglaSindical && this.modalDataReglaSindical.id) {
        this.reglaSindicalDto = { ...this.modalDataReglaSindical }
      } else {
        this.reglaSindicalDto.obligatorio = true
      }
    },
    generarRandomId() {
      return `generated${Math.random().toString(36).substring(7)}`
    },
    async agregarNuevaReglaSindical() {
      return this.$refs.refReglaSindical.validate().then(async success => {
        if (success) {
          this.findByNombres()
          if (this.reglaSindicalDto.id === null) {
            this.reglaSindicalDto.id = this.generarRandomId()
            this.$emit('agregarReglaSindical', this.reglaSindicalDto)
          } else {
            this.$emit('setReglaSindical', this.reglaSindicalDto)
          }
        }
      })
    },
    closeModal() {
      this.$emit('closeModalReglaSindical')
    },
    findByNombres() {
      this.reglaSindicalDto.afiliadoDocumentoCategoria.id = this.idDocumentoSindical
      this.optionsAfiliadoDocumentoCategoria.forEach(element => {
        if (element.id === this.reglaSindicalDto.afiliadoDocumentoCategoria.id) {
          this.reglaSindicalDto.afiliadoDocumentoCategoria.nombre = element.nombre
        }
      })
      this.optionsCategoriaSindical.forEach(element => {
        if (element.id === this.reglaSindicalDto.categoriaSindical.id) {
          this.reglaSindicalDto.categoriaSindical.nombre = element.nombre
        }
      })
    },
    getAfiliadoDocumentoCategoriaLista() {
      this.isBusyBuscandoAfiliadoDocumentoCategoria = true
      return afiliadoDocumentoCategoriaResource().findAllByIsActivoAfiCategoriaDocumentacion().then(result => {
        this.optionsAfiliadoDocumentoCategoria = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoAfiliadoDocumentoCategoria = false
        })
    },
    getCategoriaSindicalLista() {
      this.isBusyBuscandoCategoriaSindical = true
      return categoriaSindicalResource().findAllCategoriaSindical().then(result => {
        this.optionsCategoriaSindical = result
      }).catch(error => {
        console.error('error:', error)
      })
        .finally(() => {
          this.isBusyBuscandoCategoriaSindical = false
        })
    },
  },
}
</script>
