/* eslint-disable no-shadow */
import axios from 'axios'
import {
  findAllDocCategoriasAfiliado,
  getByIsActivoCategoriaDocumentoAfiliado,
  postSaveCategoriaDocumentoAfiliado,
  putUpdateCategoriaDocumentoAfiliado,
} from '@/utils/paths'

const pathGetAllDocCategorias = findAllDocCategoriasAfiliado
const pathGetByIsActivoAfiCategoriaDocumentacion = getByIsActivoCategoriaDocumentoAfiliado
const pathPostSaveAfiCategoriaDocumentacion = postSaveCategoriaDocumentoAfiliado
const pathPutUpdateAfiCategoriaDocumentacion = putUpdateCategoriaDocumentoAfiliado

export function afiliadoDocumentoCategoriaResource() {
  function findAllDocCategoriasAfiliado() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          pathGetAllDocCategorias,
        )
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function findAllByIsActivoAfiCategoriaDocumentacion() {
    return new Promise((resolve, reject) => {
      axios
        .get(pathGetByIsActivoAfiCategoriaDocumentacion)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function saveAfiCategoriaDocumentacion(categoriaDocumentacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .post(pathPostSaveAfiCategoriaDocumentacion, categoriaDocumentacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  function updateAfiCategoriaDocumentacion(id, categoriaDocumentacionDto) {
    return new Promise((resolve, reject) => {
      axios
        .put(pathPutUpdateAfiCategoriaDocumentacion + id, categoriaDocumentacionDto)
        .then(results => {
          resolve(results.data)
        })
        .catch(e => {
          reject(e.response)
        })
    })
  }
  return {
    findAllDocCategoriasAfiliado,
    findAllByIsActivoAfiCategoriaDocumentacion,
    saveAfiCategoriaDocumentacion,
    updateAfiCategoriaDocumentacion,
  }
}
